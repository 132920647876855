<template>
  <footer class="flex flex-col py-4 text-center text-slate-500 gap-1">
    <p class="text-sm">
      Zero Bypass Limited
    </p>
    <p class="text-xs">
      Copyright © {{ new Date().getFullYear() }}
    </p>
  </footer>
</template>
