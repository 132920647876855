<template>
  <div class="fixed z-[90] bottom-4 right-4 flex flex-col justify-center items-end gap-4">
    <LandingButton href="mailto:zbldoccontrol@d4r7.sk"
      class-name="drop-shadow-lg hover:drop-shadow-2xl scale-[1.0] hover:scale-[1.1] [&&]:duration-200" style-name="primary">
      <Icon name="uil:envelope" />
    </LandingButton>
    <LandingButton href="tel:+421949014711"
      class-name="drop-shadow-lg hover:drop-shadow-2xl scale-[1.0] hover:scale-[1.1] [&&]:duration-200" style-name="primary">
      <Icon name="uil:phone" />
    </LandingButton>
  </div>
</template>
